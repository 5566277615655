import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class UserService {
  public showLoader = false;
  private isAuthenticated = false;
  private isAuthenticatedStatus = new Subject<{isAuthenticated: boolean, message?: string}>();

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }
  saveRole(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'user/roles/create', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'user/roles/update', data );
    }
  }
  saveRolePemissions(data) {
    return this.http.post<any>(environment.API_URL + 'user/roles/set_permission', data );
  }
  deleteRole(id) {
    return this.http.delete<any>(environment.API_URL + 'role/delete/' + id );
  }
  getUsers() {
    return this.http.get<any>(environment.API_URL + 'user/list' );
  }
  getUser(id){
    return this.http.get<any>(environment.API_URL + 'user/details/' +  id);
  }
  resetPassword(email){
    return this.http.get<any>(environment.API_URL + 'user/reset-password/' + email);
  }
  saveProfile(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'user/register', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'user/update', data );
    }
  }
  deleteProfile(id){
    return this.http.delete<any>(environment.API_URL + 'user/delete/' + id );
  }
  getUserRoles() {
    return this.http.get<any>(environment.API_URL + 'user/roles_list' );
  }
  getOrganisations() {
    return this.http.get<any>(environment.API_URL + 'organisation/list' );
  }

  public uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(environment.API_URL + 'user/imageUpload', formData );
  }

  updatePassword(data){
    return this.http.post<any>(environment.API_URL + 'user/updatePassword', data);
  }
}
