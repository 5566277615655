<div class="header bg-gradient-danger py-7 py-lg-2">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-9">

        </div>
      </div>
    </div>
  </div>
  <div class="separator separator-bottom separator-skew zindex-100">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>
<!-- Page content -->
<div class="container">
  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div *ngFor="let event of events" class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <div class="card event-item">
        <h5 class="card-title event-name">{{event?.name}}</h5>
        <div>
          <img src="{{imageBaseUrl+event.image_path}}" class="card-img-top" alt="Skyscrapers"/>
        </div>
        <div class="card-body">
          <h3 class="card-title" *ngIf="event.venue_address">{{event.venue_address}}</h3>
          <h5 class="card-title" *ngIf="(event.start_date !=null && event.end_date !=null); else dateNa">Date : {{event.start_date | date}} to {{event.end_date | date}}</h5>
          <ng-template #dateNa>
            <h5 class="card-title" >Date : NA</h5>
          </ng-template>
        </div>
        <div class="card-footer">
         <a [routerLink]="['/event-register/', event.id]" class="btn btn-primary">View Event</a>
        </div>
      </div>
    </div>
  </div>
</div>
