import { Component, OnInit } from '@angular/core';
import {EventsService} from "../../../services/events.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  public events: any;
  imageBaseUrl = environment.API_URL + '/uploads/';
  constructor(private eventService: EventsService,) { }

  ngOnInit(): void {
    this.eventService.getEvents(true).subscribe((events)=> {
      this.events = events;
    });
  }

}
