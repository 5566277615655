import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class OrganisationsService {
  public showLoader = false;
  private isAuthenticated = false;
  private isAuthenticatedStatus = new Subject<{isAuthenticated: boolean, message?: string}>();

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }
  getOrganisations() {
    return this.http.get<any>(environment.API_URL + 'organisation/list' );
  }
  saveProfile(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'organisation/register', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'organisation/update', data );
    }
  }
  deleteProfile(id) {
    return this.http.delete<any>(environment.API_URL + 'organisation/delete/' + id );
  }
  saveCardLayout(data) {
    return this.http.post<any>(environment.API_URL + 'organisation/saveCardLayout', data );
  }
  getCardLayouts(org_id) {
    return this.http.get<any>(environment.API_URL + 'organisation/get_card_layouts/' +  org_id );
  }
  public uploadCardLayoutImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    //return this.http.post('/api/v1/image-upload', formData);
    return this.http.post<any>(environment.API_URL + 'organisation/CardLayoutImage', formData );
  }
  deleteCardProfile(id){
    return this.http.delete<any>(environment.API_URL + 'organisation/CardLayoutDelete/' + id );
  }
  updateStatus(id){
    return this.http.post<any>(environment.API_URL + 'organisation/updateStatus', {id: id} );
  }
}
