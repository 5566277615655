import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class EventsService {
  public showLoader = false;
  private isAuthenticated = false;
  private isAuthenticatedStatus = new Subject<{isAuthenticated: boolean, message?: string}>();

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }
  saveEventType(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'event/types/create', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'event/types/update', data );
    }
  }
  getEventTypes() {
    return this.http.get<any>(environment.API_URL + 'event/types' );
  }
  deleteEventType(id) {
    return this.http.delete<any>(environment.API_URL + 'event/types/delete/' + id );
  }

  public uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    //return this.http.post('/api/v1/image-upload', formData);
    return this.http.post<any>(environment.API_URL + 'event/imageUpload', formData );
  }
  saveEvent(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'event/create', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'event/update', data );
    }
  }

  getEvents(public_list =false) {
    if(public_list == true){
      return this.http.get<any>(environment.API_URL + 'event-list' );
    }else{
      return this.http.get<any>(environment.API_URL + 'event/list' );
    }

  }
  getEventDetail(id, public_access = false) {
    if(public_access == true){
      return this.http.get<any>(environment.API_URL + 'event-details/' + id );
    }else{
      return this.http.get<any>(environment.API_URL + 'event/details/' + id );
    }
  }
  public generateCards(eventId){
    return this.http.get<any>(environment.API_URL + 'event/generateCards/' + eventId );
  }
  public generateCertificates(eventId){
    return this.http.get<any>(environment.API_URL + 'event/generateCertificates/' + eventId );
  }
}
