import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class SessionService {
  public showLoader = false;

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }

  saveEventSession(data, isNew) {
    if (isNew) {
      return this.http.post<any>(environment.API_URL + 'event/sessions/create', data );
    } else {
      return this.http.post<any>(environment.API_URL + 'event/sessions/update', data );
    }
  }
  getEventSessions(id, public_access = false) {
    if(public_access == true){
      return this.http.get<any>(environment.API_URL + 'event-sessions/' + id );
    }else{
      return this.http.get<any>(environment.API_URL + 'event/sessions/' + id );
    }
  }
  deleteSession(id) {
    return this.http.delete<any>(environment.API_URL + 'event/sessions/delete/' + id );
  }
}
