import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'argon-dashboard-angular';
  isAuthenticated: boolean;
  isAuthenticatedStatus: Subscription;
  constructor(
    private authSV: AuthService,
    private router: Router) {
  }
  ngOnInit() {
    /*this.isAuthenticatedStatus = this.authSV.isAuthenticatedListener()
      .subscribe((data: any) => {
        this.isAuthenticated = data;
        if (this.isAuthenticated) {
          this.router.navigate(['admin/dashboard']);
        }
      });*/
    this.authSV.validateTokenAndSignIn();
  }
}
