import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {catchError, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = this.authService.getAuthToken();
      const substrings = ['event-list','event-register', 'event-sessions', 'event-details'];
      if(substrings.some(s => request.url.includes(s))) {
        console.log("Public url");
      }else{
        if (token) {
          // If we have a token, we set it to the header
          request = request.clone({
            setHeaders: {Authorization: `Authorization Bearer ${token}`}
          });
        } else {
          this.authService.SignOut();
        }
      }
      return next.handle(request).pipe(
          catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                  if ((err.status === 500 && err.error.message === 'Expired token')) {
                      this.authService.SignOut();
                  }
              }
              return throwError(err);
          })
      );
    }
}
