<div class="main-content">
  <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
    <div class="container px-4">
     <!-- <a class="navbar-brand" routerLinkActive="active" [routerLink]="['/dashboard']">
        <img src="assets/img/brand/infoeventorg.png" />
      </a>-->
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
         aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLinkActive="active" [routerLink]="['/dashboard']">
                <img src="assets/img/brand/blue.png">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed" >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <!--<ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/dashboard']">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner&#45;&#45;text">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/register']">
              <i class="ni ni-circle-08"></i>
              <span class="nav-link-inner&#45;&#45;text">Register</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/login']">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner&#45;&#45;text">Login</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/user-profile']">
              <i class="ni ni-single-02"></i>
              <span class="nav-link-inner&#45;&#45;text">Profile</span>
            </a>
          </li>
        </ul>-->
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
<footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
         <!-- &copy; {{ test | date: "yyyy" }} <a href="https://www.infoeventz.com?ref=ada-footer-auth-layout" class="font-weight-bold ml-1" target="_blank">Infoeventz</a>-->
        </div>
      </div>
    </div>
  </div>
</footer>
