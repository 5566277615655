import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  public showLoader = false;
  private isAuthenticated = false;
  private isAuthenticatedStatus = new Subject<{isAuthenticated: boolean, message?: string}>();

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }
  // Sign in with email/password
  SignIn(email: string, password: string) {
   this.http.post<any>(environment.API_URL + 'user/login', {email, password}, ).subscribe({
      complete: () => {  }, // completeHandler
      error: () => {
        //call laravel http://local.eventinfome.com/login
        this.http.post<any>('http://local.eventinfome.com/login', {email, password}, ).subscribe({
          next: value => {
            console.log(value)
          },
          error: err => {
            console.log(err)
          },
          complete: () => {

          }
        });
        this.isAuthenticated = false;
        this.isAuthenticatedStatus.next({isAuthenticated: this.isAuthenticated});
      },    // errorHandler
      next: (response) => {
        this.setAuthData(response);
        console.log('am here to dashboard', response);
        this.router.navigate(['admin/dashboard']);
        },     // nextHandler
    });
  }

  setAuthData(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('permissions', JSON.stringify(data.permission));
    this.isAuthenticated = true;
    this.isAuthenticatedStatus.next({isAuthenticated: this.isAuthenticated});
  }
  getAuthToken(): string {
    return localStorage.getItem('token');
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {

  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Sign out
  SignOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    this.isAuthenticated = false;
    this.isAuthenticatedStatus.next({isAuthenticated: this.isAuthenticated});
    //this.router.navigate(['/auth/login']);
    //this.validateTokenAndSignIn();
    //this.router.navigate(['/auth/login']);

    /*setTimeout(()=>{
      location.reload();
    },100)*/
    setTimeout(()=>{
      this.router.navigate(['/auth/login']);
    },100);
  }
  isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  isAuthenticatedListener() {
    return this.isAuthenticatedStatus.asObservable();
  }

  validateTokenAndSignIn() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      return;
    }
    localStorage.setItem('user', JSON.stringify(user));
    this.isAuthenticated = true;
    this.isAuthenticatedStatus.next({isAuthenticated: this.isAuthenticated});
  }
}
