import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AttendeeService {
  public showLoader = false;

  constructor(
      private http: HttpClient,
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

  }

  saveEventAttendee(data, isNew, register_public=false) {
    if(register_public){
      return this.http.post<any>(environment.API_URL + 'register-for-session', data );
    }else{
      if (isNew) {
        return this.http.post<any>(environment.API_URL + 'event/attendees/create', data );
      } else {
        return this.http.post<any>(environment.API_URL + 'event/attendees/update', data );
      }
    }
  }
  getEventAttendees(id) {
    return this.http.get<any>(environment.API_URL + 'event/attendees/' + id );
  }
  getEventDetail(id) {
    return this.http.get<any>(environment.API_URL + 'event/details/' + id );
  }
  deleteAttendee(id) {
    return this.http.delete<any>(environment.API_URL + 'event/attendees/delete/' + id );
  }

  public importAttendees(sessionID,csvfile: File) {
    const formData = new FormData();
    formData.append('sessionID',sessionID)
    formData.append('csvfile', csvfile);
    return this.http.post<any>(environment.API_URL + 'event/attendees/import_csv', formData );
  }


}
