<div class="header bg-gradient-danger py-7 py-lg-2">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-9">
          <img src="{{imageUrl}}" width="100%" height="200px">
        </div>
      </div>
    </div>
  </div>
  <div class="separator separator-bottom separator-skew zindex-100">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>
<!-- Page content -->
<div class="container mt--8 pb-5">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-9">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-2">
          <div class="text-center mt-2 mb-2"><h1 class="text-primary">Welcome to {{eventDetail?.name}}</h1></div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <!-- <small>sign in with credentials</small>-->
          </div>
          <form role="form" [formGroup]="registrationForm">
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <select class="form-control" placeholder="Session" type="text" formControlName="session_id">
                  <option value="">Select session</option>
                  <option *ngFor="let eventSession of eventSessions" value="{{eventSession.id}}">{{eventSession.day_date + '(' + eventSession.start_time + ' - ' + eventSession.end_time + ') ' +  eventSession.title}}</option>
                </select>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <!-- <div class="input-group-prepend">
                   <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                 </div>-->
                <input class="form-control" placeholder="Firstname" type="text" formControlName="firstname"/>
                <!-- <div class="input-group-prepend">
                   <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                 </div>-->
                <input class="form-control" placeholder="Lastname" type="text" formControlName="lastname"/>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <!--<div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                </div>-->
                <input class="form-control" placeholder="Email" type="email" formControlName="email"/>
                <!-- <div class="input-group-prepend">
                   <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                 </div>-->
                <input class="form-control" placeholder="Mobile" type="text" formControlName="mobile"/>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <!--<div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                </div>-->
                <input class="form-control" placeholder="Designation" type="text" formControlName="designation"/>
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    DOB
                  </span>
                </div>
                <input class="form-control" placeholder="DOB" type="date" formControlName="dob"/>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-primary my-4" (click)="register()">Register</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
