import {Component, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    enable: boolean
}
/*
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
    { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];
*/

/*export const ROUTES: RouteInfo[] = [
  { path: '/admin/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-danger', class: '', enable: true },
  { path: '/admin/organisations', title: 'Organisations',  icon: 'fas fa-sitemap text-danger', class: '', enable: (permissions.organisaton_manage == '1')?true:false},
  { path: '/admin/roles', title: 'User Roles',  icon: 'fas fa-users text-danger', class: '' , enable: (permissions.role_manage == '1')?true:false},
  { path: '/admin/users', title: 'Users',  icon: 'fas fa-users text-danger', class: '' , enable:(permissions.user_manage == '1')?true:false},
  { path: '/admin/event-types', title: 'Event Types',  icon: 'fa-calendar fas text-danger', class: '', enable: (permissions.event_manage == '1')?true:false},
  { path: '/admin/events', title: 'Events',  icon: 'fa-calendar fas text-danger', class: '', enable: (permissions.event_manage == '1')?true:false},
];*/
export const ROUTES: RouteInfo[] = [
  { path: '/admin/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-danger', class: '', enable: false },
  { path: '/admin/organisations', title: 'Organisations',  icon: 'fas fa-sitemap text-danger', class: '', enable: false},
  { path: '/admin/roles', title: 'User Roles',  icon: 'fas fa-users text-danger', class: '' , enable: false},
  { path: '/admin/users', title: 'Users',  icon: 'fas fa-users text-danger', class: '' , enable:false},
  { path: '/admin/event-types', title: 'Event Types',  icon: 'fa-calendar fas text-danger', class: '', enable: false},
  { path: '/admin/events', title: 'Events',  icon: 'fa-calendar fas text-danger', class: '', enable: false},
  { path: '/admin/reports', title: 'Reports',  icon: 'fa-file fas text-danger', class: '', enable: false},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges) {

  }
  ngOnInit() {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if(permissions == null) {
      permissions = {
        event_manage: false,
        role_manage: false,
        user_manage: false,
        organisaton_manage: false,
        reports_manage: false
      };
    }

    ROUTES.map((item)=>{
      switch (item.title) {
        case 'Dashboard':
          item.enable = true;
        break;
        case 'Organisations':
          item.enable = (permissions.organisaton_manage == '1')?true:false;
          break;
        case 'User Roles':
          item.enable = (permissions.role_manage == '1')?true:false;
          break;
        case 'Users':
          item.enable = (permissions.user_manage == '1')?true:false;
          break;
        case 'Event Types':
          item.enable = (permissions.event_manage == '1')?true:false;
          break;
        case 'Events':
          item.enable = (permissions.event_manage == '1')?true:false;
          break;
        case 'Reports':
          item.enable = (permissions.reports_manage == '1')?true:false;
          break;
      }
    });

    this.menuItems = ROUTES.filter(menuItem => menuItem.enable==true);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
