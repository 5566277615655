import { Component, OnInit } from '@angular/core';
import {EventsService} from "../../services/events.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "../../services/session.service";
import {AttendeeService} from "../../services/attendee.service";

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: ['./event-registration.component.scss']
})
export class EventRegistrationComponent implements OnInit {
  eventDetail: any;
  eventID: string;
  imageUrl: string;
  public registrationForm: FormGroup;
  eventSessions: any;
  public events: any;
  imageBaseUrl = environment.API_URL + '/uploads/';
  constructor(private fb: FormBuilder, private eventService: EventsService, private route: ActivatedRoute, private sessionService: SessionService, private attendeesService: AttendeeService) {
    this.eventID = this.route.snapshot.paramMap.get('EventID');
    this.registrationForm = this.fb.group({
      session_id: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      dob: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getPageInit();
  }
  getPageInit(){
   //get the event details
    this.eventDetail = this.eventService.getEventDetail(this.eventID, true).subscribe((data) => {
            this.eventDetail = data;
      this.imageUrl = environment.API_URL + '/uploads/' + data.image_path;
    });
    this.sessionService.getEventSessions(this.eventID, true).subscribe((eventSessions)=> {
      this.eventSessions = eventSessions;
      console.log(eventSessions);
    });
  }
  register() {
    this.attendeesService.saveEventAttendee(this.registrationForm.value, false, true).subscribe((data)=> {
      this.registrationForm.reset();
    });
  }
}
