import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(private elementRef:ElementRef) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelector('.navbar-toggler')
      .addEventListener('click', this.onClick.bind(this));
  }

  onClick(event) {
    if(document.getElementsByClassName('isCollapsedNo').length){
      document.getElementById("main-content").style.marginLeft = "50px";
      document.getElementById("navbar-brand-img").style.display = "none";
    }else{
      document.getElementById("main-content").style.marginLeft = "250px";
      document.getElementById("navbar-brand-img").style.display = "block";

    }
   /* if(document.getElementsByClassName('isCollapsedYes').length){
      document.getElementById("main-content").style.marginLeft = "50px";
    }else{
      document.getElementById("main-content").style.marginLeft = "250px";
    }*/
  }
}
