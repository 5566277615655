import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import {AuthInterceptorService} from './services/auth_interceptor.service';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './services/auth.guard';
import {UserService} from './services/users.service';
import { UsersComponent } from './pages/users/users.component';
import { RolesComponent } from './pages/roles/roles.component';
import { OrganisationsComponent } from './pages/organisations/organisations.component';
import {OrganisationsService} from './services/organisations.service';
import { EventTypesComponent } from './pages/event-types/event-types.component';
import { EventsComponent } from './pages/events/events.component';
import {EventsService} from './services/events.service';
import { ListComponent } from './pages/events/list/list.component';
import { EventDeatilsComponent } from './pages/events/event-deatils/event-deatils.component';
import { EventFormComponent } from './pages/events/event-deatils/event-form/event-form.component';
import { AttendeesComponent } from './pages/events/event-deatils/attendees/attendees.component';
import { SessionsComponent } from './pages/events/event-deatils/sessions/sessions.component';
import { EventRegistrationComponent } from './pages/event-registration/event-registration.component';
import { EventListComponent } from './pages/event-registration/event-list/event-list.component';
import {AppMaterialModule} from "./app.material-module";
import {MatTableFilterModule} from "mat-table-filter";
import { IdcardlayoutComponent } from './pages/organisations/idcardlayout/idcardlayout.component';
import { MyprofileComponent } from './pages/myprofile/myprofile.component';
import {ConfirmationDialogService} from "./pages/confirmation-dialog/confirmation-dialog.service";
import {ConfirmationDialogComponent} from "./pages/confirmation-dialog/confirmation-dialog.component";
import { ReportsComponent } from './pages/reports/reports.component';
import { PrintPreviewComponent } from './pages/events/event-deatils/print-preview/print-preview.component';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        AppMaterialModule,
      MatTableFilterModule
    ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    UsersComponent,
    RolesComponent,
    OrganisationsComponent,
    EventTypesComponent,
    EventsComponent,
    ListComponent,
    EventDeatilsComponent,
    EventFormComponent,
    AttendeesComponent,
    SessionsComponent,
    EventRegistrationComponent,
    EventListComponent,
    IdcardlayoutComponent,
    MyprofileComponent,
    ConfirmationDialogComponent,
    ReportsComponent,
    PrintPreviewComponent
  ],
  providers: [AuthService, AuthGuard, UserService, OrganisationsService, EventsService,ConfirmationDialogService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
